import React, { type ReactElement, type ReactNode } from "react";
import Modal from "react-bootstrap/Modal";
import styled from "styled-components";
import DialogCloseButton from "./DialogCloseButton";
import DialogTitle from "./DialogTitle";

const DialogModal = styled(Modal)`
    .modal-content {
        left: 50%;
        transform: translate(-50%, 0);
        height: ${({ height }) => height};
        border-radius: 10px;
        border: none;
        padding: ${(props) =>
            props.nopadding?.toLowerCase() === "true" ? 0 : "var(--spacing-5) var(--spacing-6)"};
        position: relative;
        width: ${(props) => (props.width ? props.width : "auto")};
    }
    z-index: 1200;
`;

const DialogHeader = styled(Modal.Header)`
    border-bottom: 0 none;
    display: block;
    padding: ${(props: { nopadding?: string }) =>
        props.nopadding?.toLowerCase() === "true" ? 0 : "0rem 1rem"};
    @media screen and (max-width: 500px) {
        padding-left: 0;
    }
`;

const DialogBody = styled(Modal.Body)`
    @media screen and (max-width: 500px) {
        padding: 0;
        .MuiFormLabel-root {
            font-size: 1.1em !important;
        }
    }
`;

const DialogFooter = styled(({ ...props }) => <Modal.Footer {...props} />)`
    border-top: 0 none;
    padding: var(--spacing-5) 0 0 0;
    flex-wrap: unset;
    justify-content: ${(props) => (props.size === "lg" ? "flex-end" : "center")};

    @media screen and (max-width: 500px) {
        justify-content: center;
    }
`;

interface DialogProps {
    show: boolean;
    onHide: () => void;
    allowClose?: boolean;
    id?: string;
    height?: string;
    width?: string;
    nopadding?: string;
    size?: "lg" | "md" | "sm";
    title?: ReactElement | string;
    className?: string;
    body?: ReactNode;
    footer?: ReactNode;
}

// TODO MVP-6776: Update this component to use MUI Dialog
const Dialog = ({
    show,
    onHide,
    allowClose = true,
    id,
    height,
    width,
    nopadding,
    size,
    title,
    className,
    body,
    footer,
}: DialogProps) => {
    const handleCloseDialog = () => {
        if (allowClose) {
            onHide();
        }
    };

    return (
        <DialogModal
            id={id}
            show={show}
            onHide={handleCloseDialog}
            height={height}
            width={width}
            nopadding={nopadding}
            size={size}
            centered
        >
            <DialogHeader nopadding={nopadding}>
                {allowClose && <DialogCloseButton onClick={handleCloseDialog} />}
                <DialogTitle>{title}</DialogTitle>
            </DialogHeader>
            <DialogBody className={className}>{body}</DialogBody>
            {footer && (
                <DialogFooter className={className} size={size}>
                    {footer}
                </DialogFooter>
            )}
        </DialogModal>
    );
};
export { Dialog as default, type DialogProps };
