import React from "react";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { AlertVariantKind } from "../../../constants/alertVariantKinds";
import MeasurableContainer from "../MeasurableContainer/MeasurableContainer";
import AlertMessage from "./AlertMessage";

/**
 * @typedef ClosableAlertMessagePropsType
 * @property {React.ReactNode} content - Alert message content.
 * @property {AlertVariantKind} variant - Alert message variant.
 * @property {React.MouseEventHandler<HTMLButtonElement>} onClose - Close button click handler.
 * @property {boolean} [fixedPosition] - Whether the alert message should be fixed.
 * @property {(size: import("../MeasurableContainer/MeasurableContainer").Size) => void} [onSizeChange] - Container size change handler.
 */

/**
 * ClosableAlertMessage component.
 * @param {ClosableAlertMessagePropsType} props Component props.
 * @returns {JSX.Element} ClosableAlertMessage component.
 */
const ClosableAlertMessage = ({
    content,
    variant = AlertVariantKind.Error,
    onClose: handleAlertMessageClick,
    fixedPosition,
    onSizeChange: handleContainerSizeChange,
}) => {
    const { t } = useTranslation();

    const renderAlertMessage = () => (
        <AlertMessage
            content={content}
            variant={variant}
            onClose={handleAlertMessageClick}
            AlertProps={{
                action: (
                    <IconButton
                        aria-label={t("common.close")}
                        className="p-1"
                        color="inherit"
                        onClick={handleAlertMessageClick}
                        size="large"
                    >
                        <CloseIcon fontSize="small" />
                    </IconButton>
                ),
            }}
        />
    );
    if (fixedPosition) {
        return (
            <MeasurableContainer
                className="fixed !z-10 inset-x-[1.1em] md:!left-[23em] md:!right-8 lg:!left-[26em] lg:!right-16 !bottom-36"
                onSizeChange={handleContainerSizeChange}
            >
                {renderAlertMessage()}
            </MeasurableContainer>
        );
    } else {
        return renderAlertMessage();
    }
};

ClosableAlertMessage.propTypes = {
    variant: PropTypes.string,
    content: PropTypes.node.isRequired,
    onClose: PropTypes.func.isRequired,
    fixedPosition: PropTypes.bool,
    onSizeChange: PropTypes.func,
};

export default ClosableAlertMessage;
