export const AlertVariantKind = {
    Success: "success",
    Error: "error",
    Info: "info",
    Warning: "warning",
} as const;

export type AlertVariantKind = typeof AlertVariantKind[keyof typeof AlertVariantKind];

// Map that associates a variant with a color
export const variantColorMap = {
    [AlertVariantKind.Success]: "var(--color-success-mid)",
    [AlertVariantKind.Error]: "var(--color-error-mid)",
    [AlertVariantKind.Info]: "var(--color-info-mid)",
    [AlertVariantKind.Warning]: "var(--color-warning-mid)",
};

/**
 * Determines the color based off of variant
 * @param variant - determines the color (defaults to "success")
 * @returns color string
 */
export const getVariantColor = (variant: AlertVariantKind = AlertVariantKind.Success) =>
    variantColorMap[variant];
