const ProductKind = {
    Unknown: "unknown",
    MailDemandLetterCertifiedMailStandardShipping: "mailDemandLetterCertifiedMailStandardShipping",
    PlaintiffTrialPresentation: "plaintiffTrialPresentation",
    MailSettlementLetterCertifiedMailStandardShipping:
        "mailSettlementLetterCertifiedMailStandardShipping",
    DemandLetterCopy: "demandLetterCopy",
    SettlementLetterCopy: "settlementLetterCopy",
    AdditionalMailPages: "additionalMailPages",
    AttorneyDemandLetterReview: "attorneyDemandLetterReview",
} as const;

type ProductKind = typeof ProductKind[keyof typeof ProductKind];

export { ProductKind };
