import { trackPromise } from "react-promise-tracker";
import { useMutation } from "react-query";
import accountService from "../AccountService";
import type AccountServiceError from "../AccountServiceError";
import type { UpdatePasswordResponse } from "../types";

interface UpdatePasswordVariables {
    password: string;
    newPassword: string;
}

const useUpdatePasswordMutation = () => {
    const { isLoading, isSuccess, isError, error, mutateAsync, reset } = useMutation<
        UpdatePasswordResponse,
        AccountServiceError,
        UpdatePasswordVariables
    >((variables) => {
        const { password, newPassword } = variables;

        return trackPromise(accountService.updatePasswordAsync(password, newPassword));
    });

    const updatePassword = (password: string, newPassword: string) =>
        mutateAsync({ password, newPassword });

    return {
        updatePassword,
        isLoading,
        isSuccess,
        isError,
        error,
        clearUpdatePasswordState: reset,
    };
};

export default useUpdatePasswordMutation;
