import { useCallback } from "react";
import { trackPromise } from "react-promise-tracker";
import { useMutation } from "react-query";
import accountService from "../AccountService";
import type AccountServiceError from "../AccountServiceError";
import type { UpdateAccountDetails } from "../types";

interface UpdateNameVariables {
    firstName: string;
    lastName: string;
}

const useUpdateName = () => {
    const { isLoading, isSuccess, isError, mutateAsync, reset } = useMutation<
        UpdateAccountDetails,
        AccountServiceError,
        UpdateNameVariables
    >((variables) => {
        const { firstName, lastName } = variables;

        return trackPromise(
            accountService.updateAccountAsync({
                firstName,
                lastName,
            })
        );
    });

    const updateName = useCallback(
        (firstName: string, lastName: string) => mutateAsync({ firstName, lastName }),
        [mutateAsync]
    );

    return {
        updateName,
        isLoading,
        isSuccess,
        isError,
        clearUpdateNameState: reset,
    };
};

export default useUpdateName;
