import React from "react";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import userTrackingClient from "../../clients/UserTracking";
import imageSources from "../../constants/imageSources";
import strings from "../../constants/strings";
import Container from "../common/Container/Container";
import Typography from "../common/Typography";
import { handleChunkLoadError } from "./handleChunkLoadError";
import withMediaQuery from "./withMediaQuery";

const Image = styled.img`
    width: 50%;

    @media only screen and (max-width: 48em) {
        padding: var(--spacing-7) 0;
    }
    @media only screen and (max-width: 31.25em) {
        width: 100%;
    }
`;

const Button = styled.button`
    border-radius: 30px;
    border: 1px solid var(--color-primary-dark);
    background-color: ${(props) =>
        props.primary ? "var(--color-primary-dark)" : "var(--color-white)"};
    color: ${(props) => (props.primary ? "var(--color-white)" : "var(--color-primary-dark)")};
    font-weight: var(--font-weight-bold);
    font-size: 16px;
    margin-right: var(--spacing-3);
    padding: var(--spacing-2) var(--spacing-5);

    > a {
        color: unset;
        text-decoration: none;
    }

    @media only screen and (max-width: 31.25em) {
        width: 100%;
        margin: 0 0 var(--spacing-3) 0;
    }
`;

/**
 * React router no longer supports withRouter, but offers this equivalant
 * https://reactrouter.com/en/main/start/faq#what-happened-to-withrouter-i-need-it
 * @param {React.ComponentType} Component - Component to wrap withRouter
 * @returns {React.ComponentType} the ComponentWithRouter
 */
const withRouter = (Component) => {
    const ComponentWithRouterProp = (props) => {
        let location = useLocation();
        let navigate = useNavigate();
        let params = useParams();

        return <Component {...props} router={{ location, navigate, params }} />;
    };

    return ComponentWithRouterProp;
};

/**
 * Error Boundary component that will catch JavaScript errors anywhere in the child component tree,
 * log those errors, and display a fallback UI.
 * This component will NOT catch errors for:
 *   Event handlers
 *   Asynchronous code
 *   SSR
 *   Errors thrown in the error boundary itself
 */
class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }

    /**
     * This lifecycle runs after the component output has been rendered to the DOM.
     * @returns {void}
     */
    componentDidMount() {
        if (this.state.hasError) {
            this.setState({ hasError: false });
        }
    }

    /**
     * This lifecycle runs when the component updates
     * @param {object} prevProps - props from the previous render
     * @returns {void}
     */
    componentDidUpdate(prevProps) {
        if (prevProps.router.location !== this.props.router.location && this.state.hasError) {
            this.setState({ hasError: false });
        }
    }

    componentDidCatch(error) {
        // e.g. "Loading CSS chunk 375 failed." or "Loading chunk 340 failed."
        if (error.message.match(/Loading\s.*chunk\s.*failed/)) {
            handleChunkLoadError(error);
        } else {
            userTrackingClient.trackAppCrash(error);
        }
    }

    /**
     * This lifecycle is invoked after an error has been thrown by a descendant component. It receives
     * the error that was thrown as a parameter and should return a value to update state.
     * @returns {{hasError: boolean}} Returns updated state.
     */
    static getDerivedStateFromError() {
        // Update state so the next render will show the fallback UI.
        return { hasError: true };
    }

    render() {
        if (this.state.hasError) {
            return (
                <Container
                    display="flex"
                    flexDirection={this.props.mediaQuery ? "column" : null}
                    alignItems={this.props.mediaQuery ? "center" : null}
                    height="100vh"
                    padding="0 var(--spacing-7)"
                >
                    <Image alt="error" src={imageSources.somethingWentWrong} />
                    <Container
                        flexDirection="column"
                        justifyContent={!this.props.mediaQuery ? "center" : null}
                        alignItems={this.props.mediaQuery ? "center" : null}
                        padding="0"
                    >
                        <Typography
                            variant="h5"
                            fontWeight="var(--font-weight-bold)"
                            className="!mb-6"
                            textAlign={this.props.mediaQuery ? "center" : null}
                        >
                            {this.props.t("error_boundary.title")}
                        </Typography>
                        <Typography className="!mb-8">
                            {this.props.t("error_boundary.text")}
                        </Typography>
                        <Container display="block" height="auto" padding="0">
                            <Button primary onClick={() => this.props.router?.navigate(-1)}>
                                {this.props.t("error_boundary.back_to_safety")}
                            </Button>
                            <Button>
                                <a
                                    href={`mailto:${strings.supportEmail}?subject=${strings.errorEmailSubject}`}
                                    rel="noreferrer"
                                    target="_blank"
                                >
                                    {this.props.t("error_boundary.report_problem")}
                                </a>
                            </Button>
                        </Container>
                    </Container>
                </Container>
            );
        }

        return this.props.children;
    }
}

ErrorBoundary.propTypes = {
    t: PropTypes.func,
    router: PropTypes.object,
    mediaQuery: PropTypes.bool,
    children: PropTypes.node,
};

export default withMediaQuery("(max-width: 48em)")(withTranslation()(withRouter(ErrorBoundary)));
