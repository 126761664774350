import retry from "async-retry";
import grpcWeb, { type Metadata } from "grpc-web";
import log from "loglevel";
import ow from "ow";
import questUIStrings from "../constants/strings";
import GrpcStatusCodes from "../grpc/GrpcStatusCodes";
import { isStringWithContent } from "../utils/validationUtils";
import cookieManager from "./cookieManager";

/**
 * Gets a metadata object that optionally includes auth headers.
 * @param [includeAuthHeaders=true] - value indicating whether auth headers should be included.
 * @returns - a metadata object that optionally includes an authorization header.
 */
function getMetadata(includeAuthHeaders = true) {
    ow(includeAuthHeaders, ow.boolean);

    const metadata: Metadata = {};
    if (includeAuthHeaders) {
        const accountDetails = cookieManager.getAccountDetails();
        if (isStringWithContent(accountDetails?.authToken)) {
            metadata[
                questUIStrings.httpHeaders.authorization
            ] = `${questUIStrings.bearer} ${accountDetails.authToken}`;
        } else {
            /*
             * This should not happen if authentication is handled correctly, but
             * if it does, return the metadata without the auth headers and let the call fail.
             */
            log.debug("Error getting auth token. Auth token is null or undefined");
        }
    }
    return metadata;
}

/**
 * Executes the supplied service call in a retry loop with error handling. Retries occur on transient errors, using
 * an exponential backoff strategy.
 * @param questServiceCall - Function to make a call to QuestService.
 * @param exponentialRetryOptions - Optional parameters to tweak the exponential backoff default timeouts.
 * For more details on the options, see https://github.com/tim-kos/node-retry#retrytimeoutsoptions.
 * @returns - Await retry
 */
async function attemptQuestServiceCallAsync<T>(
    questServiceCall: () => Promise<T>,
    exponentialRetryOptions: retry.Options | null = null
) {
    ow(questServiceCall, ow.function);
    return await retry(
        async (bail: (error: Error) => void) => {
            try {
                return await questServiceCall();
            } catch (error) {
                if (
                    error instanceof grpcWeb.RpcError &&
                    GrpcStatusCodes.transientErrorCodes.includes(error.code)
                ) {
                    throw error;
                }

                // if the error was non-transient, don't retry
                let bailError;
                if (error instanceof Error) {
                    bailError = error;
                } else {
                    try {
                        bailError = new Error(JSON.stringify(error));
                    } catch {
                        // fallback in case there's an error stringifying the error
                        // like with circular references for example.
                        bailError = new Error(String(error));
                    }
                }

                bail(bailError);
            }
        },
        {
            ...(exponentialRetryOptions ?? {}),
            randomize:
                exponentialRetryOptions?.randomize ??
                exponentialBackoffDefaults.randomizeRetryFactor,
            factor: exponentialRetryOptions?.factor ?? exponentialBackoffDefaults.retryFactor,
            retries:
                exponentialRetryOptions?.retries ?? exponentialBackoffDefaults.maxNumberOfRetries,
        }
    );
}

const exponentialBackoffDefaults = {
    // We chose 5 to start with, but will adjust based on our experience
    maxNumberOfRetries: 5,
    // Use exponential backoff
    retryFactor: 2,
    // Do not randomize the retry factor
    randomizeRetryFactor: false,
};

export { attemptQuestServiceCallAsync, getMetadata };
