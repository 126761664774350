import React, { type ReactNode } from "react";
import MuiSnackbar, { type SnackbarOrigin } from "@mui/material/Snackbar";
import { AlertVariantKind } from "../../../constants/alertVariantKinds";
import { ClosableAlertMessage } from "../Alert";

/**
 * Reasons the snackbar is closing.
 * Reasons are predefined from https://mui.com/material-ui/api/snackbar/#snackbar-prop-onClose
 */
const SnackbarCloseReasons = {
    clickaway: "clickaway",
};

/**
 *  Mui transitions can break due to a missing DOM node
 * - https://mui.com/material-ui/migration/troubleshooting/#cannot-read-property-scrolltop-of-null
 * - https://github.com/mui/material-ui/issues/27154
 */
const DomNode = React.forwardRef<HTMLDivElement, React.HTMLAttributes<HTMLDivElement>>(
    (props, ref) => (
        <div ref={ref} {...props}>
            {props.children}
        </div>
    )
);
DomNode.displayName = "DomNode";

interface SnackbarProps {
    open: boolean;
    content: ReactNode;
    onClose: () => void;
    className?: string;
    variant?: AlertVariantKind;
    anchorOrigin?: SnackbarOrigin;
}

const Snackbar = ({
    open = false,
    content,
    variant = AlertVariantKind.Error,
    onClose,
    className,
    anchorOrigin = { vertical: "bottom", horizontal: "left" },
}: SnackbarProps) => {
    /**
     * Function to be called on closing the snackbar
     * @param reason - reason snackbar is closing
     */
    const handleClose = (reason = "") => {
        if (reason !== SnackbarCloseReasons.clickaway) {
            onClose();
        }
    };

    return (
        <MuiSnackbar
            anchorOrigin={anchorOrigin}
            open={open}
            onClose={(_e, reason) => handleClose(reason)}
            className={className}
        >
            <DomNode>
                <ClosableAlertMessage
                    content={content}
                    variant={variant}
                    onClose={() => handleClose()}
                />
            </DomNode>
        </MuiSnackbar>
    );
};

export default Snackbar;
